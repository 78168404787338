import _ from 'lodash'
import { planEnum } from '~/constants/plan'

const { trial, lite, standard, professional } = planEnum

export const isTrialPlan = (planId?: number) => planId === trial

export const isLitePlan = (planId?: number) => planId === lite

export const isStandardPlan = (planId?: number) => planId === standard

export const isProfessionalPlan = (planId?: number) => planId === professional

export const canDownloadMorePlan = (planId?: number) => isLitePlan(planId) || isStandardPlan(planId)

export const isHigherPlan = ({ selectedPlanId, prevPlanId }: { selectedPlanId?: planEnum, prevPlanId?: number }) => {
  if (_.isUndefined(selectedPlanId) || _.isUndefined(prevPlanId)) {
    return false
  } else {
    if (selectedPlanId > prevPlanId) {
      return true
    } else {
      return false
    }
  }
}
